import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import checkoutSvgs from 'utils/constants/checkoutSvgs';
import Circle from 'assets/icons/circle-pulse.svg';

interface UpsellListProps {
  image: string;
  title: string;
  subtitle: string;
  list: { title: string; label: string }[];
  bgColor?: string;
  mainColor: string;
  icon: string;
}

const Container = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#F3F3F8')};
  width: 100%;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled(Text)<{ mainColor: string }>`
  color: ${({ mainColor }) => mainColor};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  max-width: 26.375rem;
  margin-bottom: 0.5rem;
  @media ${tablet} {
    padding-top: 1rem;
  }
`;

const LeftContainer = styled.div`
  max-width: 33.88556rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2rem;
  @media ${tablet} {
    margin-right: 0;
  }
`;

const RightContainer = styled.div`
  max-width: 32.25rem;
  width: 100%;
`;

const ImageStyled = styled(DynamicImage)``;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 25.875rem;
  margin-top: 1.25rem;
  @media ${tablet} {
    margin-top: 1.5rem;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
`;
const ListTextContainer = styled.div`
  margin-left: 0.75rem;
  @media ${tablet} {
    max-width: unset;
  }
`;

const ListTitle = styled(Text)`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.25rem;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ListLabel = styled(Text)`
  color: #4d4d4d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CircleStyled = styled.div`
  width: 2rem;
  height: 2rem;
`;

const Subtitle = styled(Text)`
  color: #4d4d4d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const UpsellList: FC<UpsellListProps> = ({
  image,
  list,
  title,
  subtitle,
  bgColor,
  mainColor,
  icon,
}) => {
  const { isTablet } = useQuery();
  const renderSvgImage = (svgKey: string) => {
    const Svg = checkoutSvgs[svgKey];
    return <Svg />;
  };
  const renderItem = () =>
    list?.map(({ label, title }, index) => (
      <ListItem key={index}>
        <CircleStyled>
          <Circle />
        </CircleStyled>
        <ListTextContainer>
          <ListTitle>{title}</ListTitle>
          <ListLabel>{label}</ListLabel>
        </ListTextContainer>
      </ListItem>
    ));
  return (
    <Container bgColor={bgColor}>
      <InnerContainer>
        {isTablet ? null : (
          <LeftContainer>
            <ImageStyled src={image} alt="" />
          </LeftContainer>
        )}

        <RightContainer>
          {!isTablet ? null : (
            <LeftContainer>
              <ImageStyled src={image} alt="" />
            </LeftContainer>
          )}
          <Title
            dangerouslySetInnerHTML={{ __html: title }}
            mainColor={mainColor}
          />
          <Subtitle>{subtitle}</Subtitle>

          <ListContainer>{renderItem()}</ListContainer>
        </RightContainer>
      </InnerContainer>
    </Container>
  );
};

export default UpsellList;
